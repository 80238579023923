<template lang="pug">
.container
  page-title
    router-link.button.is-primary(:to='inpointNewSchedule') Nueva programación
  .card
    .card-content
      schedules-table(:items='schedules' :is-loading='isLoading' @removed='getSchedules')
</template>

<script>
import SchedulesTable from './blocks/SchedulesTable'
import { inpointNewSchedule } from '@/router/routes'
import schedulesService from '@/services/schedules.service'

export default {
  components: { SchedulesTable },
  data () {
    return {
      schedules: [],
      isLoading: false,
      inpointNewSchedule
    }
  },
  created () {
    this.getSchedules()
  },
  methods: {
    async getSchedules () {
      this.isLoading = true
      const result = await schedulesService.getAll()
      if (result) {
        this.schedules = result
      }
      this.isLoading = false
    }
  }
}
</script>
