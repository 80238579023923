<template lang="pug">
b-table(:data='items' :loading='isLoading' narrowed hoverable mobile-cards striped searchable)
  b-table-column(field='startOn' label='Iniciando el' sortable searchable v-slot='props')
    | {{ props.row.startOn | datetime }}
  b-table-column(field='times' label='Veces' sortable searchable v-slot='props') {{ props.row.times }}
  b-table-column(field='each' label='Cada' sortable searchable v-slot='props') {{ props.row.each }}
  b-table-column(field='unitOfMeasureDescription' label='Unidad' sortable searchable v-slot='props') {{ props.row.unitOfMeasureDescription }}
  b-table-column(field='totalLocations' label='Sitios asociados' sortable searchable v-slot='props') {{ props.row.totalLocations }}
  b-table-column(v-slot='props')
    a(@click='removeSchedule(props.row)')
      icon(icon='trash')
  template(slot='empty')
    .no-data-found
  b-modal(v-model='invoiceModalActive' has-modal-card trap-focus aria-role='dialog' aria-modal :can-cancel='["x"]')
    modal-card(v-if='invoiceSelected' title='Editar factura')
      app-form(@submit.prevent='submit' :is-saving='isSaving')
        field(label='Estado' field='status')
          app-select(id='status' :enum='invoiceStatusEnum' :enum-names='invoiceStatusDescriptions' v-model.number='invoiceSelected.status' required)
</template>

<script>
import { invoiceStatusEnum, invoiceStatusDescriptions, unitOfMeasureDescriptions } from '@/constants/enums'
import invoicesService from '@/services/invoices.service'
import toastService from '@/services/toast.service'
import schedulesService from '@/services/schedules.service'

export default {
  components: { },
  props: {
    items: { type: Array, required: true },
    isLoading: { type: Boolean }
  },
  data () {
    return {
      invoiceModalActive: false,
      invoiceSelected: null,
      isSaving: false,
      invoiceStatusEnum,
      invoiceStatusDescriptions
    }
  },
  watch: {
    items: {
      inmediate: true,
      handler: function () {
        this.items.forEach(invoice => {
          this.formatItem(invoice)
        })
      }
    }
  },
  methods: {
    formatItem (item) {
      item.unitOfMeasureDescription = unitOfMeasureDescriptions[item.unitOfMeasure]
    },
    editInvoice (invoice) {
      this.invoiceSelected = { ...invoice }
      this.invoiceModalActive = true
    },
    removeSchedule (schedule) {
      toastService.confirm('¿Desea eliminar esta programación?, no podrá recuperarla después', async () => {
        const result = await schedulesService.delete(schedule)
        if (result) {
          toastService.show('Programación eliminada')
          this.$emit('removed', schedule)
        }
      })
    },
    async submit () {
      this.isSaving = true
      const result = await invoicesService.save(this.invoiceSelected)
      if (result) {
        const invoice = this.invoices.find(i => i.id === this.invoiceSelected.id)
        toastService.show('Factura actualizada')
        if (invoice) {
          invoice.status = this.invoiceSelected.status
          this.formatInvoice(invoice)
        }
        this.invoiceModalActive = false
      }
      this.isSaving = false
    }
  }
}
</script>
